import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import 'moment/locale/fr';
import PageTemplate from './pages/PageTemplate';
import MainPage from './pages/MainPage';
import NewsPage from './pages/NewsPage';
import SearchPage from './pages/SearchPage';
import ResultPage from './pages/ResultPage';
import MapPart from './components/result/parts/MapPart';
import SpagPart from './components/result/parts/SpagPart';
import BoatPart from './components/result/parts/BoatPart';
import MealPart from './components/result/parts/MealPart';
import InfoPart from './components/result/parts/InfoPart';
import AdminLoginPage from './pages/admin/AdminLoginPage';
import AdminPage from './pages/admin/AdminPage';
import SplashScreen from './components/SplashScreen';
import InterB2BAxios from './Service/InterB2BAxios';
import 'react-device-emulator/lib/styles/style.css';
import { resultsPageUrl } from './pages/ResultPage';

/*const TitleContext = React.createContext({
  title: 'Címsor',
  setTitle: () => {}
});*/

class App extends React.Component {

  constructor(props) {
    super(props);
    this.interAxios = new InterB2BAxios(this);
    //console.log('lefut a constructor, localStorage.state=' + window.localStorage.getItem('state'));
    this.state = {
      //originalLatitude: 46.207354, // Geneva top
      //originalLongitude: 6.148707, 
      //originalLatitude: 46.393403, // St-Gingolph
      //originalLongitude: 6.806138,
      //originalLatitude: 46.45483, // Rolle
      //originalLongitude: 6.337309,
      originalLatitude: -200, // original
      originalLongitude: -200,
      withoutPerimeter: true,
      splashScreen: true,
      title: 'Címsor',
      isDate: false,
      isSideNavOpen: false,
      nyit: false
    }
    let self = this;
    navigator.geolocation.getCurrentPosition(function(position) {
        self.setState({
          originalLatitude: position.coords.latitude,
          originalLongitude: position.coords.longitude
        });
    });
  }


  componentDidMount() {
    let self = this;
    setTimeout(() => { self.setState({splashScreen: false})}, 3000);
    this.onSearchImmediate();
  }

  onSearchImmediate = () => {
    let self = this;
      this.interAxios.get('/v1/searchImmediate', {params: {lat: this.state.originalLatitude, lng: this.state.originalLongitude}},  function(response)
      {
          self.setState({piers: response.data.piers
          });
          if(response.data.piers.length === 0){
            self.setState({withoutPerimeter: false})
          } 
          if (response.data.piers.length > 0) {
            self.setState({closestPier: response.data.piers[0]});
          }
      });
  }

  setTitle = (title) => {
      this.setState({ title: title});

  }

  handleSideNavToggle = () => {
		this.setState((state, props) => ({
			isSideNavOpen: !state.isSideNavOpen,
      nyit: !state.nyit,
		}));
	};

  handleSideNavClose = () => {
		this.setState((state, props) => ({
			isSideNavOpen: !state.isSideNavOpen,
      nyit: false,
		}));
  }


  render() {
//    let withPaddingX = (window.innerWidth -500)/2;
    //window.innerWidth =   window.innerWidth > 500 ? 500 : window.innerWidth;
    window.resizeTo(window.innerWidth > 500 ? 500 : window.innerWidth, window.innerHeight);
    return (
      <div style={{maxWidth: "500px"}}>
          {this.state.splashScreen ?
          <SplashScreen/>:
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<PageTemplate title={this.state.title} resultPage={window.location.pathname.includes("result")} {...this} {...this.state}/>}>
                <Route index element={<NewsPage {...this} {...this.state}/>} />
                <Route path="/search" element={<SearchPage {...this} {...this.state}/>} />
                <Route path={resultsPageUrl} element={<ResultPage {...this} />}>
                  <Route index element={<MapPart {...this} />} />
                  <Route path="map" element={<MapPart {...this} />} />
                  <Route path="spag" element={<SpagPart {...this} closestPier={this.state.closestPier}/>} />
                  <Route path="boat" element={<BoatPart {...this} />} />
                  <Route path="meal" element={<MealPart {...this} />} />
                  <Route path="info" element={<InfoPart {...this} />} />
                </Route>
                <Route path="*" element={<MainPage {...this} />} />
              </Route>
              <Route path="/admin" element={<AdminPage/>}/>
              <Route path="/admin/login" element={<AdminLoginPage/>}/>
            </Routes>
          </BrowserRouter>}
      </div>
    );
  }
}

export default App;
