import React from 'react';
import { Outlet, useParams, useLocation, useSearchParams } from 'react-router-dom';
import {
  MDBContainer,
  MDBRow,
  MDBCol
} from 'mdbreact';
import Moment from 'moment';
import 'moment/locale/fr';
import InterB2BAxios from '../Service/InterB2BAxios';
import BoatDisplay from '../components/result/BoatDisplay';
import LinesDisplay from '../components/result/LinesDisplay';
import PierDisplay from '../components/result/PierDisplay';
import Navigation from '../components/result/Navigation';

import styles from '../assets/scss/modules/pagetemplate.module.scss';

export const resultsPageUrl = '/results';
export const resultsPageMapUrl = '/results/map';

class ResultPage extends React.Component {


  constructor(props) {
    super(props);
    this.interAxios = new InterB2BAxios(this);
    this.state = {lines: [
                          {
                            num: 1
                          },
                          {
                            num: 2
                          },
                          {
                            num: 3
                          },
                          {
                            num: 4
                          },
                          {
                            num: 5
                          },
                          {
                            num: 6
                          },
                          {
                            num: 7
                          },
                          {
                            num: 8
                          },
                          {
                            num: 9
                          },
                          {
                            num: 10
                          },
                          {
                            num: 11
                          },
                          {
                            num: 12
                          },
                          {
                            num: 13
                          },
                          {
                            num: 14
                          }
                        ],
                  selectedLine: undefined
                 };
  };

  componentDidMount() {
    this.props.setTitle(this.props.date !== undefined ? Moment(this.props.date).locale('fr').format('dddd DD MMMM YYYY').replace(/\b[a-z]/, match => match.toUpperCase()):
    Moment(Date.now()).locale('fr').format('dddd DD MMMM YYYY').replace(/\b[a-z]/, match => match.toUpperCase()));
    this.onSearch();
    this.setState({heightSize: window.innerWidth < 325 ? window.innerHeight -206 : window.innerHeight -216})
  }

  onSearch = () => {
//    console.log(this.props.date + ", " + this.props.pier + ", " + this.props.boat);
    let self = this;
    let paramObj = {date: this.props.date};
    if (this.props.pier !== "Tous") {
      paramObj["pierId"] = this.props.pier;
    }
    if (this.props.boat !== "Tous") {
      paramObj["boatId"] = this.props.boat;
    }
    this.interAxios.get('/v1/search', {params: paramObj}, function(response)
      {
          self.setState({lines: response.data.lines});
          self.onLineSelect(response.data.lines[0]?.num);
      });
  }

  onLineSelect = (line) => {
    this.setState({selectedLine: line});
  }

  render() {
    const context = [this.state.selectedLine, this.props.date, this.props.xkoord, this.state.heightSize];
    let heightSize = this.state.heightSize +85;
    return(
      <div className={styles.resultpagebodywrapper + ' ' + styles.bodywrapperbackground}>
      <MDBContainer className="m-0 p-0 container-height">
        <MDBRow className="m-0 p-0 w100">
          <MDBCol middle size="6" className="m-0 p-0">
            <PierDisplay pier={this.props.pierName}/>
          </MDBCol>
          <MDBCol middle size="6" className="m-0 p-0">
            <BoatDisplay boat={this.props.boatName}/>
          </MDBCol>
        </MDBRow>
        <MDBRow className="m-0 p-0 w100">
          <MDBCol middle size="12" className="p-0">
            <LinesDisplay lines={this.state.lines} onSelect={this.onLineSelect} selected={this.state.selectedLine} />
          </MDBCol>
        </MDBRow>
        <MDBRow className="m-0 p-0 w100">
          <div className="mappart-format p-0 m-0 align-items-center justify-content-center" >
            <Outlet context={context}/>
          </div>
        </MDBRow>


      </MDBContainer>
      <div className="navigation-feld">
          <Navigation search={this.props.search} selected={this.state.selectedLine === undefined ? 1 : this.state.selectedLine}/>
      </div>
      </div>
    );
  }
}

export default function ResultPageFunc(props) {
  let { date, pier, boat, pierName, boatName, xkoord } = useParams();
  let location = useLocation();
  let [searchParams] = useSearchParams();
  return <ResultPage date={searchParams.get('date')}
                     pier={searchParams.get('pier')}
                     boat={searchParams.get('boat')}
                     pierName={searchParams.get('pierName')}
                     boatName={searchParams.get('boatName')}
                     xkoord={searchParams.get('xkoord')}
                     search={location.search} {...props}/>;
}
