import React from 'react';
import { Link, useNavigate, createSearchParams } from 'react-router-dom';
import DatePicker from 'react-mobile-datepicker';
import { BrowserView, MobileView, isBrowser, isMobile, isSafari } from 'react-device-detect';
import { browserName, CustomView } from 'react-device-detect';
import { Picker } from 'react-mobile-style-picker';
import 'react-mobile-style-picker/dist/index.css';
import styles from '../assets/scss/modules/pagetemplate.module.scss';
import Moment from 'moment';
import VerticalPicker from '../components/picker/VerticalPicker'
import WithoutSeasonModal from '../components/popup/Modals/WithoutSeasonModal'
import InterB2BAxios from '../Service/InterB2BAxios';
import uszogumi from "../assets/media/images/img.png";
import ocean from "../assets/media/images/ocean.png";
import small from "../assets/media/images/small.png";
import 'moment/locale/fr';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBSelect,
  MDBInput,
  MDBBtn,
} from 'mdbreact';

import NotFound from '../components/search/NotFound';
import ImmediateEmbark from '../components/search/ImmediateEmbark';
import { resultsPageUrl, resultsPageMapUrl } from './ResultPage';

class SearchPage extends React.Component {

  constructor(props) {
    super(props);
    this.interAxios = new InterB2BAxios(this);
    this.state = {news: [],
                  isInActiveSeason: false,
                  pierName: "Tous",
                  boatName: "Tous",
                  boatUrl: "text",
                  boatId: "Tous",
                  pierId: "Tous",
                  isOpen: false,
                  isOpenPier: false,
                  isOpenBoat: false,
                  pierValue: 'ALL',
                  boatValue: 'ALL',
                  withoutSeasonModal: false
                  };
  };

  componentDidMount() {
    this.props.setTitle('Choisir ma croisière');
    this.onActiveSeason(Moment(this.state.time).format('YYYY-MM-DD'));
  }

  onActiveSeason = (date) => {
    let self = this;
    this.interAxios.get('/v1/date/isInActiveSeason', {params: {date: date}},  function(response)
    {
        self.setState({isInActiveSeason: response.data});
        if(response.data){
          self.onActivePiers(date);
        }
    });
  }

  onWithoutSeason = () => {
    this.setState({withoutSeasonModal: true});
  }

  handleClose = () => {
    this.setState({withoutSeasonModal: false});
  }

  onNot = () => {

  }

  onActivePiers = (date, boatId) => {
    let self = this;
      this.interAxios.get('/v1/piers', {params: {date: date, boatIds: boatId}},  function(response)
      {
          self.setState({piers: response.data});
          self.setState({valuePier: response.data.length,});
          self.onActiveBoats(Moment(date).format('YYYY-MM-DD'), "Tous");
      });
  }

  onActiveBoats = (date, pierId) => {
    let self = this;
    if(pierId == "Tous"){
      this.interAxios.get('/v1/boats', {params: {date: date}},  function(response)
      {
          self.setState({boats: response.data});
          self.setState({valueBoat: response.data.length,});

      });
    }else{
      this.interAxios.get('/v1/boats', {params: {date: date, pierIds: pierId}},  function(response)
      {
          self.setState({boats: response.data});
          self.setState({valueBoat: response.data.length,});
      });
    }

  }

  handleClick = () => {
		this.setState({ isOpen: true });
	}

	handleCancel = () => {
		this.setState({ isOpen: false });
	}

	handleSelect = (time) => {
		this.setState({ time, isOpen: false });
    this.onActiveSeason(Moment(time).format('YYYY-MM-DD'));
	}

  handleClickPier = () => {
		this.setState({ isOpenPier: true });
	}

  handleClickBoat = () => {
		this.setState({ isOpenBoat: true });
	}

	handleCancelPier = () => {
		this.setState({ isOpenPier: false });
	}

  handleCancelBoat = () => {
		this.setState({ isOpenBoat: false });
	}

	handleSelectPier = () => {

      if(this.state.valuePier === this.state.piers.length){
        this.setState({pierName: "Tous", isOpenPier: false });
          this.onActiveBoats(Moment(this.state.time).format('YYYY-MM-DD'), "Tous");
      }else{
        if(this.state.piers[this.state.valuePier]?.important){
          this.setState({pierName: this.state.piers[this.state.valuePier].name, pierId: this.state.piers[this.state.valuePier].id, isOpenPier: false });
          this.onActiveBoats(Moment(this.state.time).format('YYYY-MM-DD'), this.state.piers[this.state.valuePier].id);
        }else{
            this.setState({isOpenPier: false });
            this.onActiveBoats(Moment(this.state.time).format('YYYY-MM-DD'), "Tous");
        }
	    }

  }

  handleSelectBoat = () => {

      if(this.state.valueBoat === this.state.boats.length){
        this.setState({boatName: "Tous", boatUrl: "text", isOpenBoat: false, boatId: undefined });

      }else{
          this.setState({boatName: this.state.boats[this.state.valueBoat].name, boatUrl: this.state.boats[this.state.valueBoat].pictureData, boatId:  this.state.boats[this.state.valueBoat].id, isOpenBoat: false });
	    }
      this.onActivePiers(Moment(this.state.time).format('YYYY-MM-DD'), this.state.boats[this.state.valueBoat].id);
  }

  onChangePier = (value) => {
      this.setState({valuePier: value});
  }

  onChangeBoat = (value) => {
      this.setState({valueBoat: value});
  }


  render() {
    const monthMap = {
    	'1': 'janvier',
    	'2': 'février',
    	'3': 'mars',
    	'4': 'avril',
    	'5': 'mai',
    	'6': 'juin',
    	'7': 'juillet',
    	'8': 'août',
    	'9': 'septembre',
    	'10': 'octobre',
    	'11': 'novembre',
    	'12': 'décembre',
    };
    const dateConfig = {
              'month': {
                  format: value => monthMap[value.getMonth() + 1],
                  caption: 'Mon',
                  step: 1,
              },
              'date': {
                  format: 'D',
                  caption: 'Day',
                  step: 1,
              }
          };
    return <div className={styles.searchpagebodywrapper + ' ' + styles.bodywrapperbackground}>
      <div className="search-form">
      <MDBContainer className="search-form-container">
        <MDBRow>
          <div className="align-self-center search-form-header-col text-right pl-0">
            <span className="search-title">Date</span>
          </div>
          <div className="align-self-center search-form-input-col">
            <a
  					className="select-btn m-0"
  					onClick={this.handleClick}>
              <MDBInput id='date'
                        className='font-black font-futura-condensed font-18px'
                        disabled
                        type='text'
                        value={this.state.time !== undefined ?
                          Moment(this.state.time).locale('fr').format('dddd DD MMMM YYYY').replace(/\b[a-z]/, match => match.toUpperCase()):
                          Moment(Date.now()).locale('fr').format('dddd DD MMMM YYYY').replace(/\b[a-z]/, match => match.toUpperCase())}/>
            </a>
          </div>
        </MDBRow>
        <MDBRow>
          <div className="align-self-center search-form-header-col text-right pl-0">
            <span className="search-title">Débarcadère</span>
          </div>
          <div className="align-self-center search-form-input-col">
            <a
            className="select-btn"
            onClick={this.state.isInActiveSeason ? this.handleClickPier : this.onWithoutSeason}>
            <MDBInput id='pier'
                      type='text'
                      className='font-black font-futura-condensed font-18px'
                      disabled
                      value={this.state.pierName}/>
            </a>
          </div>
        </MDBRow>
        <MDBRow>
          <div className="align-self-center search-form-header-col text-right pl-0">
            <span className="search-title">Bateau</span>
          </div>

          <div className="align-self-center search-form-input-col">
          <a
          className="select-btn"
          onClick={this.state.isInActiveSeason ? this.handleClickBoat : this.onWithoutSeason}>
          <MDBInput id='boat'
                    type='text'
                    className='font-black font-italic font-futura-condensed font-18px'
                    disabled
                    value={this.state.boatName}/>
          </a>
          </div>
        </MDBRow>
      </MDBContainer>
      <div className="search-body">
      <MDBRow center className="m-0 pt-2 pb-2">
      <Link to={this.state.isInActiveSeason ? resultsPageUrl + `?date=${Moment(this.state.time).format('YYYY-MM-DD')}&pier=${this.state.pierId}&boat=${this.state.boatId}&pierName=${this.state.pierName}&boatName=${this.state.boatName}` : "#"}>
        <MDBBtn flat className="p-0 m-0" style={{textTransform: "unset"}} onClick={!this.state.isInActiveSeason ? this.onWithoutSeason : this.onNot}>
        <div className="uszogumi-text-3">
          <MDBRow center className="pt-0 m-0">
            <span className="uszogumi-text-a">Livre</span>
          </MDBRow>
          <MDBRow center className="pt-0 m-0">
            <span className="uszogumi-text-b">de bord</span>
          </MDBRow>
          </div>
          <img className="uszogumi-img" src={uszogumi}/>
        </MDBBtn>
        </Link>
      </MDBRow>
      </div>
      <div style={{height: "210px", paddingTop: "15px"}}>
      <ImmediateEmbark
            originalLatitude={this.props.originalLatitude}
            originalLongitude={this.props.originalLongitude}
            withoutPerimeter={this.props.withoutPerimeter}
            isInActiveSeason={this.state.isInActiveSeason}
            piers={this.props.piers}/>
      </div>
      {/*<NotFound/>*/}
      {this.state.isOpenPier ?
        <VerticalPicker
          list= {this.state.piers}
          onChange = {this.onChangePier}
          value = {this.state.valuePier}
          handleCancel = {this.handleCancelPier}
          handleSelect = {this.handleSelectPier}
          itemClassName = "pier-picker-text" />
      :false}
      {this.state.isOpenBoat ?
        <VerticalPicker
          list= {this.state.boats}
          onChange = {this.onChangeBoat}
          value = {this.state.valueBoat}
          handleCancel = {this.handleCancelBoat}
          handleSelect = {this.handleSelectBoat}
          itemClassName = "boat-picker-text" />
      :false}
        <DatePicker
          value={this.state.time}
          isOpen={this.state.isOpen}
          onSelect={this.handleSelect}
          onCancel={this.handleCancel}
          theme={'ios'}
          headerFormat={''}
          dateConfig={dateConfig}
          min={new Date(2021, 0, 1)}
          max={new Date(2052, 12, 31)}
          confirmText={'OK'}
          cancelText={'Annuler'}
        />
      {this.state.withoutSeasonModal ?
      <WithoutSeasonModal
        show= {this.state.withoutSeasonModal}
        handleClose = {this.handleClose}/>:false}

    </div>
    </div>;
  }
}

export default SearchPage;
