import React from 'react';
import { Picker } from 'react-mobile-style-picker';
import 'react-mobile-style-picker/dist/index.css'
/*import {
  MDBRow,
  MDBCol,
  MDBIcon
} from 'mdbreact';*/
import marker from '../../assets/media/images/marker.png';


class HorizontalPicker extends React.Component {
  state={
    pastille: [
      {value: 1, activecolor: "#A51F25", inactivecolor: "#E4BCBE"},
      {value: 2, activecolor: "#32468A", inactivecolor: "#C1C7DC"},
      {value: 3, activecolor: "#BB5D22", inactivecolor: "#EBCEBD"},
      {value: 4, activecolor: "#008447", inactivecolor: "#B2DAC8"},
      {value: 5, activecolor: "#762571", inactivecolor: "#D6BED4"},
      {value: 6, activecolor: "#C66664", inactivecolor: "#EED1D1"},
      {value: 7, activecolor: "#C59323", inactivecolor: "#EEDFBD"},
      {value: 8, activecolor: "#009F9F", inactivecolor: "#B2E2E2"},
      {value: 9, activecolor: "#777776", inactivecolor: "#D6D6D6"},
      {value: 10, activecolor: "#995229", inactivecolor: "#E0CBBF"},
      {value: 11, activecolor: "#819729", inactivecolor: "#D9E0BF"},
      {value: 12, activecolor: "#996996", inactivecolor: "#E0D2DF"},
      {value: 13, activecolor: "#B09E7E", inactivecolor: "#E7E2D8"},
      {value: 14, activecolor: "#C25A90", inactivecolor: "#EDCDDE"},
    ],
    /*pastille: [
      {value: 1, activecolor: "#E67E78"},
      {value: 2, activecolor: "#59AFD6"},
      {value: 3, activecolor: "#ED9F2D"},
      {value: 4, activecolor: "#6CB644"},
      {value: 5, activecolor: "#948EB9"},
      {value: 6, activecolor: "#FFBCC1"},
      {value: 7, activecolor: "#F2D11C"},
      {value: 8, activecolor: "#7AC4B2"},
      {value: 9, activecolor: "#A29E9B"},
      {value: 10, activecolor: "#BF8466"},
      {value: 11, activecolor: "#95AB2E"},
      {value: 12, activecolor: "#B373A3"},
      {value: 13, activecolor: "#D4B18C"},
      {value: 14, activecolor: "#E680A9"},
    ],*/
    selected: 0
  }
//#59AFD6
  componentDidMount() {
    let selected = 0;
    for(let j = 0; j < this.props.list.length; j++) {
      if(this.props.list[j].num === this.props.selected){
        selected = j;
      }
    }
    this.setState({ selected: selected });
  }
  onChange= (value) => {
      this.setState({selected: value});
      this.props.onSelect(this.props.list[value].num);
  }
  onSize = (index) => {
    let sizeText = "35px";
    let x = Math.abs(index-this.state.selected);
    if(x === 0){
      sizeText = "35px";
    }else{
      if(x === 1){
        sizeText = "33px";
      }else{
        if(x === 2){
          sizeText = "29px";
        }else{
          if(x === 3){
            sizeText = "25px";
          }else{
            sizeText = "21px";
          }
        }
      }
    }
    return sizeText;
  }

  onSizeText = (index) => {
    let sizeText = "";
    let x = Math.abs(index-this.state.selected);
    if(x === 0){
      sizeText = "30px";
    }else{
      if(x === 1){
        sizeText = "28px";
      }else{
        if(x === 2){
          sizeText = "25px";
        }else{
          if(x === 3){
            sizeText = "21px";
          }else{
            sizeText = "18px";
          }
        }
      }
    }
    return sizeText;
  }

  render() {
    let horizontalItems = this.props.list.map((item, index) => {
      return <Picker.Item
            className="d-flex align-items-center justify-content-center"
            value={index}
            key={index}>
        <div style={{backgroundColor: index === this.state.selected ? this.state.pastille[item.num - 1].activecolor : this.state.pastille[item.num - 1].inactivecolor,
              width: this.onSize(index),
              height: this.onSize(index)
                   }}
             className={this.state.selected !== index ? "btn-none-opacity d-flex align-items-center justify-content-center" : "btn-none-opacity d-flex align-items-center justify-content-center"}>
              <span className="horizontalpicker-text" style={{fontSize: this.onSizeText(index)}}>{item.num}</span>
        </div>
      </Picker.Item>})

    let count = window.innerWidth < 330 ? 7 : 9;
    return (
        <div className="horizontalpicker d-flex align-items-center justify-content-center">
          <Picker mode={'horizontal'} mask={[0,1]} onChange={this.onChange} value={this.state.selected} size={count}>
            {horizontalItems}
          </Picker>
          <div className="vl"><img src={marker}/></div>
        </div>
    );
  }
}

export default HorizontalPicker;
