import React, { Component } from "react";
import ReactDOM from 'react-dom';
import retour from "../assets/media/images/retour1.png";
import { Link } from 'react-router-dom';

class ReturnButton extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  };

  render() {

    if (window.location.href.split("/")[window.location.href.split("/").length - 1] === "") {
      if (this.props.isSideNavOpen) {
        return <button type="button" className="btn btn-flat p-0 m-0" onClick={this.props.handleSideNavToggle}><img className="retour-btn" src={retour}/></button>;
      } else {
        return false;
      }
    } else {
      return <Link to="/"><img className="retour-btn" src={retour}/></Link>;
    }
  }
}
export default ReturnButton;
