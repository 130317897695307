import React from 'react';
import {
  MDBContainer,
  MDBNavbar,
  MDBIcon,
  MDBNavbarNav,
  MDBNavItem,
  MDBNavLink
} from 'mdbreact';
import { Link } from "react-router-dom";
import ocean from "../../assets/media/images/ocean.png";
import small from "../../assets/media/images/small.png";

/*import leman_1 from "../../assets/media/images/Icons/Leman_1.png";
import leman_2 from "../../assets/media/images/Icons/Leman_2.png";
import leman_3 from "../../assets/media/images/Icons/Leman_3.png";
import leman_4 from "../../assets/media/images/Icons/Leman_4.png";
import leman_5 from "../../assets/media/images/Icons/Leman_5.png";
import leman_6 from "../../assets/media/images/Icons/Leman_6.png";
import leman_7 from "../../assets/media/images/Icons/Leman_7.png";
import leman_8 from "../../assets/media/images/Icons/Leman_8.png";
import leman_9 from "../../assets/media/images/Icons/Leman_9.png";
import leman_10 from "../../assets/media/images/Icons/Leman_10.png";
import leman_11 from "../../assets/media/images/Icons/Leman_11.png";
import leman_12 from "../../assets/media/images/Icons/Leman_12.png";
import leman_13 from "../../assets/media/images/Icons/Leman_13.png";
import leman_14 from "../../assets/media/images/Icons/Leman_14.png";

import horloge_1 from "../../assets/media/images/Icons/Horloge_1.png";
import horloge_2 from "../../assets/media/images/Icons/Horloge_2.png";
import horloge_3 from "../../assets/media/images/Icons/Horloge_3.png";
import horloge_4 from "../../assets/media/images/Icons/Horloge_4.png";
import horloge_5 from "../../assets/media/images/Icons/Horloge_5.png";
import horloge_6 from "../../assets/media/images/Icons/Horloge_6.png";
import horloge_7 from "../../assets/media/images/Icons/Horloge_7.png";
import horloge_8 from "../../assets/media/images/Icons/Horloge_8.png";
import horloge_9 from "../../assets/media/images/Icons/Horloge_9.png";
import horloge_10 from "../../assets/media/images/Icons/Horloge_10.png";
import horloge_11 from "../../assets/media/images/Icons/Horloge_11.png";
import horloge_12 from "../../assets/media/images/Icons/Horloge_12.png";
import horloge_13 from "../../assets/media/images/Icons/Horloge_13.png";
import horloge_14 from "../../assets/media/images/Icons/Horloge_14.png";

import bateau_1 from "../../assets/media/images/Icons/Bateau_1.png";
import bateau_2 from "../../assets/media/images/Icons/Bateau_2.png";
import bateau_3 from "../../assets/media/images/Icons/Bateau_3.png";
import bateau_4 from "../../assets/media/images/Icons/Bateau_4.png";
import bateau_5 from "../../assets/media/images/Icons/Bateau_5.png";
import bateau_6 from "../../assets/media/images/Icons/Bateau_6.png";
import bateau_7 from "../../assets/media/images/Icons/Bateau_7.png";
import bateau_8 from "../../assets/media/images/Icons/Bateau_8.png";
import bateau_9 from "../../assets/media/images/Icons/Bateau_9.png";
import bateau_10 from "../../assets/media/images/Icons/Bateau_10.png";
import bateau_11 from "../../assets/media/images/Icons/Bateau_11.png";
import bateau_12 from "../../assets/media/images/Icons/Bateau_12.png";
import bateau_13 from "../../assets/media/images/Icons/Bateau_13.png";
import bateau_14 from "../../assets/media/images/Icons/Bateau_14.png";

import resto_1 from "../../assets/media/images/Icons/Resto_1.png";
import resto_2 from "../../assets/media/images/Icons/Resto_2.png";
import resto_3 from "../../assets/media/images/Icons/Resto_3.png";
import resto_4 from "../../assets/media/images/Icons/Resto_4.png";
import resto_5 from "../../assets/media/images/Icons/Resto_5.png";
import resto_6 from "../../assets/media/images/Icons/Resto_6.png";
import resto_7 from "../../assets/media/images/Icons/Resto_7.png";
import resto_8 from "../../assets/media/images/Icons/Resto_8.png";
import resto_9 from "../../assets/media/images/Icons/Resto_9.png";
import resto_10 from "../../assets/media/images/Icons/Resto_10.png";
import resto_11 from "../../assets/media/images/Icons/Resto_11.png";
import resto_12 from "../../assets/media/images/Icons/Resto_12.png";
import resto_13 from "../../assets/media/images/Icons/Resto_13.png";
import resto_14 from "../../assets/media/images/Icons/Resto_14.png";

import gouvernail_1 from "../../assets/media/images/Icons/Gouvernail_1.png";
import gouvernail_2 from "../../assets/media/images/Icons/Gouvernail_2.png";
import gouvernail_3 from "../../assets/media/images/Icons/Gouvernail_3.png";
import gouvernail_4 from "../../assets/media/images/Icons/Gouvernail_4.png";
import gouvernail_5 from "../../assets/media/images/Icons/Gouvernail_5.png";
import gouvernail_6 from "../../assets/media/images/Icons/Gouvernail_6.png";
import gouvernail_7 from "../../assets/media/images/Icons/Gouvernail_7.png";
import gouvernail_8 from "../../assets/media/images/Icons/Gouvernail_8.png";
import gouvernail_9 from "../../assets/media/images/Icons/Gouvernail_9.png";
import gouvernail_10 from "../../assets/media/images/Icons/Gouvernail_10.png";
import gouvernail_11 from "../../assets/media/images/Icons/Gouvernail_11.png";
import gouvernail_12 from "../../assets/media/images/Icons/Gouvernail_12.png";
import gouvernail_13 from "../../assets/media/images/Icons/Gouvernail_13.png";
import gouvernail_14 from "../../assets/media/images/Icons/Gouvernail_14.png";*/


import leman_1 from "../../assets/media/images/Icons2/Leman_1.png";
import leman_2 from "../../assets/media/images/Icons2/Leman_2.png";
import leman_3 from "../../assets/media/images/Icons2/Leman_3.png";
import leman_4 from "../../assets/media/images/Icons2/Leman_4.png";
import leman_5 from "../../assets/media/images/Icons2/Leman_5.png";
import leman_6 from "../../assets/media/images/Icons2/Leman_6.png";
import leman_7 from "../../assets/media/images/Icons2/Leman_7.png";
import leman_8 from "../../assets/media/images/Icons2/Leman_8.png";
import leman_9 from "../../assets/media/images/Icons2/Leman_9.png";
import leman_10 from "../../assets/media/images/Icons2/Leman_10.png";
import leman_11 from "../../assets/media/images/Icons2/Leman_11.png";
import leman_12 from "../../assets/media/images/Icons2/Leman_12.png";
import leman_13 from "../../assets/media/images/Icons2/Leman_13.png";
import leman_14 from "../../assets/media/images/Icons2/Leman_14.png";
import leman_background from "../../assets/media/images/Icons2/Leman_background.png";

import horloge_1 from "../../assets/media/images/Icons2/Horloge_1.png";
import horloge_2 from "../../assets/media/images/Icons2/Horloge_2.png";
import horloge_3 from "../../assets/media/images/Icons2/Horloge_3.png";
import horloge_4 from "../../assets/media/images/Icons2/Horloge_4.png";
import horloge_5 from "../../assets/media/images/Icons2/Horloge_5.png";
import horloge_6 from "../../assets/media/images/Icons2/Horloge_6.png";
import horloge_7 from "../../assets/media/images/Icons2/Horloge_7.png";
import horloge_8 from "../../assets/media/images/Icons2/Horloge_8.png";
import horloge_9 from "../../assets/media/images/Icons2/Horloge_9.png";
import horloge_10 from "../../assets/media/images/Icons2/Horloge_10.png";
import horloge_11 from "../../assets/media/images/Icons2/Horloge_11.png";
import horloge_12 from "../../assets/media/images/Icons2/Horloge_12.png";
import horloge_13 from "../../assets/media/images/Icons2/Horloge_13.png";
import horloge_14 from "../../assets/media/images/Icons2/Horloge_14.png";
import horloge_background from "../../assets/media/images/Icons2/Horloge_background.png";

import bateau_1 from "../../assets/media/images/Icons2/Bateau_1.png";
import bateau_2 from "../../assets/media/images/Icons2/Bateau_2.png";
import bateau_3 from "../../assets/media/images/Icons2/Bateau_3.png";
import bateau_4 from "../../assets/media/images/Icons2/Bateau_4.png";
import bateau_5 from "../../assets/media/images/Icons2/Bateau_5.png";
import bateau_6 from "../../assets/media/images/Icons2/Bateau_6.png";
import bateau_7 from "../../assets/media/images/Icons2/Bateau_7.png";
import bateau_8 from "../../assets/media/images/Icons2/Bateau_8.png";
import bateau_9 from "../../assets/media/images/Icons2/Bateau_9.png";
import bateau_10 from "../../assets/media/images/Icons2/Bateau_10.png";
import bateau_11 from "../../assets/media/images/Icons2/Bateau_11.png";
import bateau_12 from "../../assets/media/images/Icons2/Bateau_12.png";
import bateau_13 from "../../assets/media/images/Icons2/Bateau_13.png";
import bateau_14 from "../../assets/media/images/Icons2/Bateau_14.png";
import bateau_background from "../../assets/media/images/Icons2/Bateau_background.png";

import resto_1 from "../../assets/media/images/Icons2/Resto_1.png";
import resto_2 from "../../assets/media/images/Icons2/Resto_2.png";
import resto_3 from "../../assets/media/images/Icons2/Resto_3.png";
import resto_4 from "../../assets/media/images/Icons2/Resto_4.png";
import resto_5 from "../../assets/media/images/Icons2/Resto_5.png";
import resto_6 from "../../assets/media/images/Icons2/Resto_6.png";
import resto_7 from "../../assets/media/images/Icons2/Resto_7.png";
import resto_8 from "../../assets/media/images/Icons2/Resto_8.png";
import resto_9 from "../../assets/media/images/Icons2/Resto_9.png";
import resto_10 from "../../assets/media/images/Icons2/Resto_10.png";
import resto_11 from "../../assets/media/images/Icons2/Resto_11.png";
import resto_12 from "../../assets/media/images/Icons2/Resto_12.png";
import resto_13 from "../../assets/media/images/Icons2/Resto_13.png";
import resto_14 from "../../assets/media/images/Icons2/Resto_14.png";
import resto_background from "../../assets/media/images/Icons2/Resto_background.png";

import gouvernail_1 from "../../assets/media/images/Icons2/Gouvernail_1.png";
import gouvernail_2 from "../../assets/media/images/Icons2/Gouvernail_2.png";
import gouvernail_3 from "../../assets/media/images/Icons2/Gouvernail_3.png";
import gouvernail_4 from "../../assets/media/images/Icons2/Gouvernail_4.png";
import gouvernail_5 from "../../assets/media/images/Icons2/Gouvernail_5.png";
import gouvernail_6 from "../../assets/media/images/Icons2/Gouvernail_6.png";
import gouvernail_7 from "../../assets/media/images/Icons2/Gouvernail_7.png";
import gouvernail_8 from "../../assets/media/images/Icons2/Gouvernail_8.png";
import gouvernail_9 from "../../assets/media/images/Icons2/Gouvernail_9.png";
import gouvernail_10 from "../../assets/media/images/Icons2/Gouvernail_10.png";
import gouvernail_11 from "../../assets/media/images/Icons2/Gouvernail_11.png";
import gouvernail_12 from "../../assets/media/images/Icons2/Gouvernail_12.png";
import gouvernail_13 from "../../assets/media/images/Icons2/Gouvernail_13.png";
import gouvernail_14 from "../../assets/media/images/Icons2/Gouvernail_14.png";
import gouvernail_background from "../../assets/media/images/Icons2/Gouvernail_background.png";

import { resultsPageUrl, resultsPageMapUrl } from '../../pages/ResultPage';
let urlName = '';

class Navigation extends React.Component {
  constructor(props) {
      super(props);
      var params = window.location.href.split("/");
  		urlName = params[params.length - 1];
      this.state = {activeItem : urlName === "results" + this.props.search ? "1" :
                  urlName === "map" + this.props.search ? "1" :
                urlName === "spag" + this.props.search ? "2" :
              urlName === "boat" + this.props.search ? "3" :
            urlName === "meal" + this.props.search ? "4" :
          urlName === "info" + this.props.search ? "5" : "1"};
  }

  componentDidMount() {
  }

  toggle = tab => e => {
				if (tab === "results" + this.props.search) {
					this.setState({
						activeItem: "1"});
				}
        if (tab === 'map' + this.props.search) {
					this.setState({
						activeItem: "1"});
				}
        if (tab === 'spag' + this.props.search) {
					this.setState({
						activeItem: "2"});
				}
        if (tab === 'boat' + this.props.search) {
					this.setState({
						activeItem: "3"});
				}
        if (tab === 'meal' + this.props.search) {
					this.setState({
						activeItem: "4"});
				}
        if (tab === 'info' + this.props.search) {
					this.setState({
						activeItem: "5"});
				}


	}
  render() {
    let letArrayLeman = [ "", leman_1, leman_2, leman_3, leman_4, leman_5, leman_6, leman_7, leman_8, leman_9, leman_10, leman_11, leman_12, leman_13, leman_14];
    let letArrayHorloge = [ "", horloge_1, horloge_2, horloge_3, horloge_4, horloge_5, horloge_6, horloge_7, horloge_8, horloge_9, horloge_10, horloge_11, horloge_12, horloge_13, horloge_14];
    let letArrayBateau = [ "", bateau_1, bateau_2, bateau_3, bateau_4, bateau_5, bateau_6, bateau_7, bateau_8, bateau_9, bateau_10, bateau_11, bateau_12, bateau_13, bateau_14];
    let letArrayResto = [ "", resto_1, resto_2, resto_3, resto_4, resto_5, resto_6, resto_7, resto_8, resto_9, resto_10, resto_11, resto_12, resto_13, resto_14];
    let letArrayGouvernail = [ "", gouvernail_1, gouvernail_2, gouvernail_3, gouvernail_4, gouvernail_5, gouvernail_6, gouvernail_7, gouvernail_8, gouvernail_9, gouvernail_10, gouvernail_11, gouvernail_12, gouvernail_13, gouvernail_14];
    return (
      <div className="navigation-nav">
      <MDBNavbar>
          <MDBNavbarNav className='d-flex flex-row'>
            <MDBNavItem>
              <div className="border-right">
              <Link to={resultsPageMapUrl + this.props.search} onClick={this.toggle('map' + this.props.search)} role="tab" className='nav-link d-flex align-items-center justify-content-center'>
                <img src={letArrayLeman[this.props.selected]} className={this.state.activeItem === "1" ? "active-icon leman-icon" : "inactive-icon leman-icon"}/>
                <img src={leman_background} className={this.state.activeItem === "1" ? "no-display leman-icon" : "inactive-icon-overlay leman-icon"}/>
              </Link>
              </div>
            </MDBNavItem>
            <MDBNavItem>
              <div className="border-right">
              <Link to={resultsPageUrl + '/spag' + this.props.search} onClick={this.toggle('spag' + this.props.search)} role="tab" className='nav-link d-flex align-items-center justify-content-center'>
                <img src={letArrayHorloge[this.props.selected]} className={this.state.activeItem === "2" ? "active-icon horloge-icon" : "inactive-icon horloge-icon"}/>
                <img src={horloge_background} className={this.state.activeItem === "2" ? "no-display horloge-icon" : "inactive-icon-overlay horloge-icon"}/>
              </Link>
              </div>
            </MDBNavItem>
            <MDBNavItem>
              <div className="border-right">
              <Link to={resultsPageUrl + '/boat' + this.props.search} onClick={this.toggle('boat' + this.props.search)} role="tab" className='nav-link d-flex align-items-center justify-content-center'>
                <img src={letArrayBateau[this.props.selected]} className={this.state.activeItem === "3" ? "active-icon bateau-icon" : "inactive-icon bateau-icon"}/>
                <img src={bateau_background} className={this.state.activeItem === "3" ? "no-display bateau-icon" : "inactive-icon-overlay bateau-icon"}/>
              </Link>
              </div>
            </MDBNavItem>
            <MDBNavItem>
              <div className="border-right">
              <Link to={resultsPageUrl + '/meal' + this.props.search} onClick={this.toggle('meal' + this.props.search)} role="tab" className='nav-link d-flex align-items-center justify-content-center'>
                <img src={letArrayResto[this.props.selected]} className={this.state.activeItem === "4" ? "active-icon resto-icon" : "inactive-icon resto-icon"}/>
                <img src={resto_background} className={this.state.activeItem === "4" ? "no-display resto-icon" : "inactive-icon-overlay resto-icon"}/>
              </Link>
              </div>
            </MDBNavItem>
            <MDBNavItem>
              <Link to={resultsPageUrl + '/info' + this.props.search} onClick={this.toggle('info' + this.props.search)} role="tab" className='nav-link d-flex align-items-center justify-content-center'>
                <img src={letArrayGouvernail[this.props.selected]} className={this.state.activeItem === "5" ? "active-icon gouvernail-icon" : "inactive-icon gouvernail-icon"}/>
                <img src={gouvernail_background} className={this.state.activeItem === "5" ? "no-display gouvernail-icon" : "inactive-icon-overlay gouvernail-icon"}/>
              </Link>
            </MDBNavItem>
          </MDBNavbarNav>
      </MDBNavbar>
      </div>
    );
  }
}

export default Navigation;
