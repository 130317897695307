import React, { Component } from "react";
import { Scrollbar } from "react-scrollbars-custom";
import { MDBContainer,
         MDBBtn,
         MDBModal,
         MDBModalBody,
         MDBModalHeader,
         MDBModalFooter,
         MDBRow,
       } from 'mdbreact';
import Moment from "moment";
import InterB2BAxios from "../../../Service/InterB2BAxios";


class WithoutSeasonModal extends Component {
  state = { start: undefined, end: undefined}
  constructor(props) {
    super(props);
    this.interAxios = new InterB2BAxios(this, "WithoutSeasonModal");
  }
  componentDidMount() {
    let self = this;
    this.interAxios.get('/v1/date/activeSeason', function(response) {
      self.setState({...response.data});
    }, function(error) {

    })
  }
render() {
  return (
    <div className="withoutseason-modal">
    <MDBModal
         isOpen={this.props.show}
         toggle={this.props.handleClose}
         side position="bottom">
      <MDBModalHeader toggle={this.props.handleClose}>
      </MDBModalHeader>
      <MDBModalBody className="pl-2 pt-2 pb-2 pr-2">

          <span className="text-center d-flex">Le NaviPlan couvre les saisons de printemps, d’été et d’automne {Moment(this.state.start).locale('fr').format('YYYY')} de
                             l’horaire CGN officiel, du {Moment(this.state.start).locale('fr').format('DD MMMM')} au {Moment(this.state.end).locale('fr').format('DD MMMM YYYY')} inclus.</span><br/>
          <span className="text-center d-flex"><span>Pour vos croisières en dehors de ces périodes, merci de bien
                             vouloir consulter l’<a href="https://cgn.ch">horaire CGN officiel</a>.</span></span><br/>

      </MDBModalBody>
    </MDBModal>
    </div>
  )
}
}

export default WithoutSeasonModal;
